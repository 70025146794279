import React from "react"
import { css } from "@emotion/core"

import Company from "./Company"

const LIST = [
  {
    altText: "Palo Alto Networks",
    image: require("images/homepage/companies/palo-alto-1x.png"),
  },
  {
    altText: "Cisco",
    image: require("images/homepage/companies/cisco-1x.png"),
  },
  {
    altText: "Crowd Strike",
    image: require("images/homepage/companies/crowdstrike-1x.png"),
  },
  {
    altText: "Check Point Software Technologies Ltd.",
    image: require("images/homepage/companies/check-point-1x.png"),
  },
  {
    altText: "Carbon Black.",
    image: require("images/homepage/companies/carbon-black-1x.png"),
  },
  {
    altText: "IBM Radar",
    image: require("images/homepage/companies/ibm-radar-1x.png"),
  },
  {
    altText: "Splunk",
    image: require("images/homepage/companies/splunk-1x.png"),
  },
  {
    altText: "Arc Sight (An HP company)",
    image: require("images/homepage/companies/arc-sight-1x.png"),
  },
]

const companies = css`
  display: flex;
  flex-wrap: wrap;
  margin-right: -40px;
  margin-bottom: -40px;
  @media (max-width: 479px) {
    flex-direction: column;
    align-items: center;
    margin-right: 0;
  }
`

function Companies() {
  return (
    <div css={companies}>
      {LIST.map((company) => {
        return <Company {...company} key={company.altText} />
      })}
    </div>
  )
}

export default Companies
