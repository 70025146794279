import React, { useState, useEffect, useRef } from "react"
import { Link } from "gatsby"
import { css } from "@emotion/core"
import Img from "gatsby-image"
import _get from "lodash/get"
import Typed from "typed.js"

import { logError } from "src/util"
import { routes } from "src/constants"
import { colors } from "css/theme"
import { h2, btn } from "css/primitives"

import content from "../content"

const heroImageWrapper = css`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 100px;
  position: relative;
  top: 300px;
  z-index: 1;
  margin-top: -350px;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;

  @media (max-width: 1199px) {
    max-width: 85%;
  }
  @media (max-width: 991px) {
    margin-top: -300px;
    top: 250px;
  }
  @media (max-width: 767px) {
    margin-top: -250px;
    top: 200px;
  }
  @media (max-width: 629px) {
    margin-top: -200px;
    top: 150px;
  }
  @media (max-width: 479px) {
    max-width: 95%;
  }
  @media (max-width: 399px) {
    margin-top: -150px;
    top: 100px;
  }
`

const wrapper = css`
  width: 100vw;
  /*margin-left: calc(550px - 50vw);*/
  background-image: radial-gradient(
    circle at top,
    ${colors.background} 0%,
    black 100%
  );
  background-repeat: no-repeat;
  padding-top: 90px;
  @media (max-width: 479px) {
    padding-top: 50px;
  }
`

const topContent = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 900px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  z-index: 2;
`

const h1 = [
  h2,
  css`
    font-size: 52px;
    line-height: 68px;
    text-align: center;
    margin-bottom: 18px;
    @media (max-width: 991px) {
      font-size: 44px;
      line-height: 1.3;
      padding-left: 1rem;
      padding-right: 1rem;
      max-width: 800px;
    }
    @media (max-width: 767px) {
      font-size: 32px;
      max-width: 600px;
    }
    @media (max-width: 550px) {
      white-space: pre-line;
    }
    @media (max-width: 399px) {
      font-size: 30px;
    }
  `,
]

const description = css`
  color: white;
  white-space: pre-line;
  opacity: 0.7;
  font-size: 20px;
  line-height: 1.5;
  margin-bottom: 30px;
  text-align: center;
  @media (max-width: 991px) {
    padding-left: 1rem;
    padding-right: 1rem;
    white-space: normal;
  }
  @media (max-width: 767px) {
    font-size: 17px;
  }
`

const buttons = css`
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 280px;
    margin-right: auto;
    margin-left: auto;
  }
`

const primaryButton = [
  btn,
  css`
    background-color: ${colors.orange};
    color: white;
    &:hover {
      color: white;
    }
    padding: 10px 3rem;
    @media (max-width: 767px) {
      margin-bottom: 1rem;
      width: 100%;
    }
  `,
]

const secondaryButton = [
  btn,
  css`
    color: ${colors.orange};
    background-color: transparent;
    border: 1px solid ${colors.orange};
    margin-left: 2rem;
    min-width: 244px;
    &:hover {
      color: ${colors.orange};
    }
    @media (max-width: 767px) {
      width: 100%;
      margin-left: 0;
    }
  `,
]

const useCases = [
  "Lateral Movement",
  "Targeted Threats",
  "Ransomware",
  "Red-teams",
  "SWIFT attacks",
  "SCADA attacks",
  "Cloud compromise",
  "Insider Threats",
]

function HeroSection({ data }) {
  const heroImage = _get(data, "heroImage.childImageSharp.fluid") || {}
  const [imageStyles, setImageStyles] = useState({ opacity: 0 })
  const typedSpanRef = useRef(null)
  const [options] = useState({
    strings: useCases,
    typeSpeed: 50,
    backSpeed: 50,
    loop: true,
    backDelay: 1500,
    // Initially Lateral Movement is provided in the <span ref={typedSpanRef}>
    // so the UI looks okay before JS is initialized
    // but that needs to be removed after the first loop or else the animation jumps to
    // initial state of "Lateral Movement" instead of ""
    onComplete: (self) => {
      self.currentElContent = ""
      self.elContent = ""
    },
  })
  useEffect(() => {
    let typedInstance = { destroy: () => {} } // noop function
    if (typedSpanRef && typedSpanRef.current) {
      typedInstance = new Typed(typedSpanRef.current, options)
    }
    return () => {
      typedInstance.destroy()
    }
  }, [typedSpanRef, options])
  return (
    <div css={wrapper}>
      <div>
        <div css={topContent}>
          <h1 css={h1}>
            {"Deception Technology for Detecting \n"}
            <span ref={typedSpanRef}>{useCases[0]}</span>
          </h1>
          <p css={description}>{content.heroImage.descriptionText}</p>
          <div css={buttons}>
            <Link to={routes.demo} css={primaryButton}>
              Schedule a demo
            </Link>
            <Link to={routes.pricing} css={secondaryButton}>
              See Pricing
            </Link>
          </div>
        </div>
        <div css={heroImageWrapper}>
          <Img
            fluid={heroImage}
            style={{ width: "100%", ...imageStyles }}
            loading="eager"
            alt="Smokescreen investigate dashboard with attacker detail view open"
            fadeIn={false}
            onLoad={() => {
              setImageStyles({ opacity: 1 })
            }}
            onError={() => {
              const error = new Error("Homepage hero image didn't load")
              logError(error)
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default HeroSection
