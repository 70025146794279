import React, { useState } from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { css } from "@emotion/core"
import _get from "lodash/get"

import { logError } from "src/util"
import { routes } from "src/constants"
import { colors, radius } from "css/theme"
import { btn } from "css/primitives"

const wrapper = css`
  background-color: ${colors.background};
  padding-left: 1rem;
  padding-right: 1rem;
`

const wrapperInner = css`
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  background-image: linear-gradient(
    45deg,
    ${colors.costSectionBlue} 0%,
    ${colors.costSectionOrange} 85%
  );
  border-radius: ${radius.big};
  padding: 2rem;
  overflow: hidden;
  box-shadow: 0 2px 4px 0 ${colors.black50};
`

const contentWrapper = css`
  margin-right: 2rem;
  @media (max-width: 767px) {
    margin-right: 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`

const imageWrapper = css`
  flex-shrink: 0;
  margin-left: auto;
  width: 100%;
  max-width: 528px;
  @media (max-width: 991px) {
    width: 50%;
  }
  @media (max-width: 767px) {
    display: none;
  }
`

const title = css`
  color: black;
  font-size: 32px;
  line-height: 1.375;
  font-family: var(--heading);
  font-weight: bold;
  margin-top: 4rem;
  margin-bottom: 1rem;
  white-space: pre-line;
  @media (max-width: 991px) {
    margin-top: 0;
    white-space: normal;
  }
  @media (max-width: 767px) {
    font-size: 26px;
  }
  @media (max-width: 479px) {
    font-size: 22px;
  }
`
const description = css`
  font-size: 18px;
  line-height: 1.55;
  color: black;
  margin-bottom: 30px;
  max-width: 400px;
  @media (max-width: 767px) {
    opacity: 0.7;
    max-width: 450px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 20px;
  }
  @media (max-width: 479px) {
    font-size: 1rem;
  }
`
const button = [
  btn,
  css`
    background-color: ${colors.orange};
    color: white;
    letter-spacing: 1px;
    line-height: 2.25;
    padding: 10px 2rem;
    display: inline-block;
    position: relative;
    z-index: 1;
    &:hover {
      color: white;
    }
  `,
]

// note: this code will be added back when we go live with IB experience center

// const subtitle = css`
//   font-size: 1rem;
//   line-height: 1.75;
//   margin-top: 6px;
//   color: #404040;
//   margin-bottom: 0;
// `

const titleText = "What does deception cost?"

function CostSection({ data }) {
  const costImage = _get(data, "costSectionImage.childImageSharp.fluid") || {}
  const [imageStyles, setImageStyles] = useState({ opacity: 0 })
  return (
    <div css={wrapper}>
      <div css={wrapperInner}>
        <div css={contentWrapper}>
          <div css={title}>{titleText}</div>
          <div css={description}>
            Much less than not knowing that someone’s about to breach your
            customer database.
          </div>
          <Link to={routes.pricing} css={button}>
            See pricing plans
          </Link>
          {/* <p css={subtitle}>No credit card required. Zero setup time</p> */}
        </div>
        <div css={imageWrapper}>
          <Img
            fluid={costImage}
            alt="Smokescreen Investigate Page Dashboard"
            style={{ width: "100%", ...imageStyles }}
            fadeIn={false}
            onLoad={() => {
              setImageStyles({ opacity: 1 })
            }}
            onError={() => {
              const error = new Error("Homepage cost section image didn't load")
              logError(error)
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default CostSection
