import { css } from "@emotion/core"

import { colors } from "css/theme"
import { h2, btn } from "css/primitives"

const subtitle = css`
  margin-bottom: 1rem;
  color: ${colors.gray};
  letter-spacing: 0.89px;
  text-transform: uppercase;
  font-family: var(--heading);
  font-size: 14px;
`

const heading = [
  h2,
  css`
    margin-bottom: 20px;
    white-space: pre-line;
  `,
]

const headingH1 = css`
  font-size: 56px;
  line-height: 64px;
`

const descriptionH1 = css`
  font-size: 20px;
  line-height: 1.5;
  margin-bottom: 27px;
`

const description = css`
  color: white;
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 40px;
  white-space: pre-line;
  opacity: 0.7;
`

const button = [
  btn,
  css`
    background-color: ${colors.orange};
    color: white;
    letter-spacing: 1px;
    line-height: 2.25;
    padding: 10px 1.5rem;
    display: inline-block;
    position: relative;
    z-index: 1;
    &:hover {
      color: white;
    }
    box-shadow: 5px 5px 8px 0 ${colors.black42};
  `,
]

const buttonHelp = css`
  color: white;
  opacity: 0.5;
  display: black;
  margin-top: 6px;
  font-size: 1rem;
  line-height: 1.75;
`

export {
  subtitle,
  heading,
  description,
  button,
  buttonHelp,
  headingH1,
  descriptionH1,
}
