import React from "react"
import { css } from "@emotion/core"

import { colors } from "css/theme"

import Notification from "images/homepage/notification-1x.png"

import content from "../content"

import SectionContentGroup from "./SectionContentGroup"

const wrapper = css`
  background-color: ${colors.background};
  margin-bottom: 150px;
  padding-left: 1rem;
  padding-right: 1rem;
  @media (max-width: 767px) {
    margin-bottom: 100px;
  }
`

const wrapperInner = css`
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`

const sectionContent = css`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 991px) {
    padding-right: 0;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: 600px;
    width: 100%;
  }
`

const imageWrapper = css`
  padding-left: 100px;
  padding-right: 100px;
  margin-right: -100px;
  width: calc(50% + 200px);
  background-image: radial-gradient(
    ellipse closest-side,
    ${colors.brightBlue} 0,
    ${colors.white10} 50%,
    transparent 100%
  );
  @media (max-width: 991px) {
    width: 100%;
    max-width: 600px;
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 3rem;
  }
`

function SignalWrapper() {
  return (
    <div css={wrapper}>
      <div css={wrapperInner}>
        <div css={sectionContent}>
          <SectionContentGroup {...content.signal} />
        </div>
        <div css={imageWrapper}>
          <img
            src={Notification}
            alt="Login attempt alert SMS shown on a mobile screen"
          />
        </div>
      </div>
    </div>
  )
}

export default SignalWrapper
