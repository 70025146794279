import React from "react"
import { css } from "@emotion/core"

import { radius, colors } from "css/theme"

const company = css`
  background-color: ${colors.dullBlack};
  width: calc(50% - 40px);
  max-width: 234px;
  height: 105px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: ${radius.big};
  padding: 1rem;
  margin-right: 40px;
  margin-bottom: 40px;
  @media (max-width: 479px) {
    margin-right: 0;
    width: 100%;
  }
`

function Company({ image, altText }) {
  return (
    <div css={company}>
      <img src={image} alt={altText} />
    </div>
  )
}

export default Company
