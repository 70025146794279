import React from "react"
import { css } from "@emotion/core"

import { colors } from "css/theme"
import { h2 } from "css/primitives"

import CustomerStars from "images/homepage/customer-success-rating-1x.png"

import content from "../content"

const wrapper = css`
  color: white;
  font-size: 20px;
  line-height: 30px;
  justify-content: center;
  padding-top: 400px;
  margin-bottom: 100px;
  @media (max-width: 1199px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  @media (max-width: 991px) {
    padding-top: 350px;
    margin-bottom: 75px;
  }
  @media (max-width: 767px) {
    padding-top: 300px;
  }
  @media (max-width: 630px) {
    padding-top: 250px;
  }
  @media (max-width: 479px) {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 220px;
  }
  @media (max-width: 399px) {
    padding-top: 175px;
  }
`

const wrapperInner = css`
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  border-top: 1px solid ${colors.grayBorder};
  border-bottom: 1px solid ${colors.grayBorder};
  display: flex;
  padding-top: 48px;
  padding-bottom: 58px;
  @media (max-width: 767px) {
    flex-direction: column;
  }
  @media (max-width: 479px) {
    padding-top: 2rem;
    padding-bottom: 2.5rem;
  }
`

const card = css`
  position: relative;
  z-index: 1;
  max-width: 600px;
  @media (max-width: 991px) {
    padding-right: 1rem;
    max-width: 450px;
  }
  @media (max-width: 767px) {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-bottom: 1rem;
  }
`

const fadedCard = css`
  display: flex;
  flex-direction: column;
  max-width: 265px;
  margin-left: auto;
  @media (max-width: 767px) {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    max-width: 450px;
  }
`

const fadedCardSubtitle = css`
  color: ${colors.gray};
  letter-spacing: 0.89;
  font-family: var(--heading);
  font-weight: 400;
  text-transform: uppercase;
  font-size: 14px;
  margin-bottom: 10px;
  white-space: pre-line;
`

const image = css`
  max-width: 265px;
`

const ratingText = css`
  font-size: 2.25rem;
  color: white;
  font-family: var(--heading);
  line-height: 1.44;
  @media (max-width: 767px) {
    margin-top: 0.5rem;
  }
`

const heading = [
  h2,
  css`
    color: white;
    opacity: 0.7;
    @media (max-width: 991px) {
      font-size: 28px;
    }
    @media (max-width: 479px) {
      font-size: 24px;
    }
  `,
]

function CustomerSuccess() {
  const t = content.customerSuccess
  return (
    <div css={wrapper}>
      <div css={wrapperInner}>
        <div css={card}>
          <h2 css={heading}>
            Happy customers in 18 geographies covering over 1.5m endpoints.
          </h2>
        </div>
        <div css={fadedCard}>
          <div css={fadedCardSubtitle}>{t.ratingHeading}</div>
          <img src={CustomerStars} alt="Stars" css={image} />
          <span css={ratingText}>{t.ratingText}</span>
        </div>
      </div>
    </div>
  )
}

export default CustomerSuccess
