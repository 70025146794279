import React from "react"
import { css } from "@emotion/core"

import { colors } from "css/theme"

import {
  HeroSection,
  CustomerSuccess,
  UpAndRunningWrapper,
  SignalWrapper,
  AutomationWrapper,
  OrchestrationSection,
  CostSection,
  HowItWorks,
  ZscalarAcquisition,
} from "./components"

const homepage = css`
  background-color: ${colors.background};
  padding-bottom: 100px;
  overflow: hidden;
  margin-top: -90px;
  @media (max-width: 479px) {
    padding-bottom: 75px;
  }
`

function Homepage({ data }) {
  return (
    <div css={homepage}>
      <ZscalarAcquisition />
      <HeroSection data={data} />
      <CustomerSuccess />
      <HowItWorks />
      <UpAndRunningWrapper />
      <SignalWrapper />
      <AutomationWrapper />
      <OrchestrationSection />
      <CostSection data={data} />
    </div>
  )
}

export default Homepage
