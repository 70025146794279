import React, { useRef, useState } from "react"
import { css } from "@emotion/core"

import { colors, radius } from "css/theme"
import { h2 } from "css/primitives"
import { If } from "src/components"
import { logError } from "src/util"

// import Illustration from 'images/homepage/how-it-works-1x.png';
import VideoMP4 from "videos/homepage/how-it-works.mp4"
import VideoWebM from "videos/homepage/how-it-works.webm"
import Poster from "videos/homepage/how-it-works.png"
import PauseIcon from "images/svg/icons/pause.svg"
import PlayIcon from "images/svg/icons/play-circle.svg"

const wrapper = css`
  background-color: ${colors.background};
  margin-bottom: 150px;
  padding-left: 1rem;
  padding-right: 1rem;
  @media (max-width: 767px) {
    margin-bottom: 100px;
  }
`

const wrapperInner = css`
  text-align: center;
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
`

const imageWrapper = css`
  border-radius: ${radius.big};
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.52);
  overflow: hidden;
  position: relative;
  img,
  video {
    max-width: 100%;
    cursor: pointer;
  }
`

const heading = [
  h2,
  css`
    margin-bottom: 75px;
    @media (max-width: 767px) {
      margin-bottom: 50px;
    }
  `,
]

const pauseWrapper = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  opacity: 0;
  transition: 0.3s ease opacity;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  fill: ${colors.orange};
  &:hover {
    opacity: 1;
  }
  svg {
    width: 140px;
    height: 140px;
  }
  @media (max-width: 1024px) {
    svg {
      width: 100px;
      height: 100px;
    }
  }
  @media (max-width: 767px) {
    svg {
      width: 75px;
      height: 75px;
    }
  }
  @media (max-width: 479px) {
    svg {
      width: 50px;
      height: 50px;
    }
  }
`

function HowItWorks() {
  const videoEl = useRef(null)
  const [isPlaying, setPlaying] = useState(false)
  const toggleVideo = (e) => {
    e.stopPropagation()
    // This would cause WEBSITE-T issues
    const el = videoEl.current
    // From https://stackoverflow.com/questions/36803176/how-to-prevent-the-play-request-was-interrupted-by-a-call-to-pause-error
    const isActuallyPlaying =
      el.currentTime > 0 && !el.paused && !el.ended && el.readyState > 2
    if (!isPlaying && !isActuallyPlaying) {
      const promise = el.play()
      // Older browsers do not return a promise.
      if (!promise) {
        setPlaying(true)
        return
      }
      promise
        .then(() => {
          setPlaying(true)
        })
        .catch((e) => {
          logError(e)
        })
    } else {
      el.pause()
      setPlaying(false)
    }
  }
  return (
    <div css={wrapper}>
      <div css={wrapperInner}>
        <h2 css={heading}>Here's how it works</h2>
        <div css={imageWrapper}>
          <div
            css={pauseWrapper}
            onClick={toggleVideo}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                toggleVideo(e)
              }
            }}
            role="button"
            tabIndex={0}
            style={isPlaying ? {} : { opacity: 1 }}
          >
            <If test={isPlaying}>
              <PauseIcon width={50} height={50} />
            </If>
            <If test={!isPlaying}>
              <PlayIcon
                width={50}
                height={50}
                style={{
                  background: "white",
                  borderRadius: "50%",
                  position: "relative",
                  bottom: "2px",
                }}
              />
            </If>
          </div>
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <video
            controls={false}
            poster={Poster}
            onClick={toggleVideo}
            ref={videoEl}
            preload="none"
          >
            <source src={VideoMP4} type="video/mp4" />
            <source src={VideoWebM} type="video/webm" />
          </video>
        </div>
      </div>
    </div>
  )
}

export default HowItWorks
