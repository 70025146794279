import React from "react"
import { css } from "@emotion/core"

import { colors } from "css/theme"

import content from "../content"

import SectionContentGroup from "./SectionContentGroup"
import Companies from "./Companies"

const wrapper = css`
  background-color: ${colors.background};
  margin-bottom: 150px;
  padding-left: 1rem;
  padding-right: 1rem;
  @media (max-width: 767px) {
    margin-bottom: 100px;
  }
`

const wrapperInner = css`
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: center;
  }
`

const sectionContent = css`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 50px;
  max-width: 470px;
  margin-right: auto;
  @media (max-width: 1099px) {
    padding-left: 0;
    padding-right: 1rem;
  }
  @media (max-width: 991px) {
    padding-right: 0;
    margin-bottom: 3rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 600px;
    width: 100%;
  }
`

const companiesWrapper = css`
  width: 50%;
  @media (max-width: 991px) {
    max-width: 600px;
    width: 100%;
  }
`

function OrchestrationSection() {
  return (
    <div css={wrapper}>
      <div css={wrapperInner}>
        <div css={sectionContent}>
          <SectionContentGroup {...content.orchestration} />
        </div>
        <div css={companiesWrapper}>
          <Companies />
        </div>
      </div>
    </div>
  )
}

export default OrchestrationSection
