import React from "react"
import { Link } from "gatsby"

import { If } from "../../../components"
import { routes } from "src/constants"

import {
  subtitle,
  heading,
  description,
  button,
  buttonHelp,
  headingH1,
  descriptionH1,
} from "../css/SectionContentGroup"

function SectionContentGroup(props) {
  const descriptionCSS = [description]
  if (props.type === "h1") {
    descriptionCSS.push(descriptionH1)
  }
  const buttonCSS = [button]
  if (props.buttonOverride) {
    buttonCSS.push(props.buttonOverride)
  }
  return (
    <div css={props.cssOverride}>
      <If test={props.subtitleText}>
        <div css={subtitle}>{props.subtitleText}</div>
      </If>
      <If test={props.type === "h1"}>
        <h1 css={[heading, headingH1]}>{props.headingText}</h1>
      </If>
      <If test={props.type !== "h1"}>
        <h2 css={heading}>{props.headingText}</h2>
      </If>
      <p css={descriptionCSS}>{props.descriptionText}</p>
      <If test={props.buttonText}>
        <Link to={props.buttonLink || routes.demo} css={buttonCSS}>
          {props.buttonText}
        </Link>
      </If>
      <If test={props.buttonHelpText}>
        <p css={buttonHelp}>{props.buttonHelpText}</p>
      </If>
    </div>
  )
}

export default SectionContentGroup
