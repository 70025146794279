import React from "react"
import { css } from "@emotion/core"

import BackgroundImage from "images/homepage/hero-background.png"
import ZscalerLogo from "images/homepage/zscaler-logo-white.png"
import SmokescreenLogo from "images/homepage/smokescreen-logo.png"

import { colors } from "css/theme"
import { btn } from "css/primitives"

const wrapper = css`
  background-image: url(${BackgroundImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 150px;
  padding-bottom: 80px;
  padding-left: 1rem;
  padding-right: 1rem;
`

const top = css`
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  @media (max-width: 599px) {
    flex-direction: column;
    margin-bottom: 20px;
  }
`

const topItem = css`
  flex-shrink: 0;
  height: 57px;
  &:first-child {
    margin-right: 46px;
    border-right: 2px solid white;
    padding-right: 46px;
  }
  img {
    height: 57px;
  }
  @media (max-width: 899px) {
    img {
      height: 44px;
    }
    &:first-child {
      margin-right: 1rem;
      border-right-width: 1px;
      padding-right: 1rem;
    }
  }
  @media (max-width: 599px) {
    img {
      height: 36px;
    }
    &:first-child {
      margin-right: 0;
      margin-bottom: 1rem;
      padding-right: 0;
      padding-bottom: 1rem;
      border-right: 0;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: calc(50% - 30px);
        width: 60px;
        border-bottom: 1px solid white;
      }
    }
  }
`

const heading = css`
  font-family: var(--heading);
  font-weight: bold;
  color: white;
  font-size: 56px;
  line-height: 1.29;
  text-align: center;
  max-width: 900px;
  @media (max-width: 899px) {
    font-size: 40px;
    max-width: 550px;
  }
  @media (max-width: 599px) {
    font-size: 32px;
  }
`

const button = [
  btn,
  css`
    text-transform: uppercase;
    background-color: ${colors.orange};
    color: white;
    text-decoration: none;
    font-size: 14px;
    line-height: 2.29;
    padding: 10px 37px;
    border-radius: 4px;
    width: 295px;
    max-width: 100%;
    margin-top: 40px;
    &:hover {
      text-decoration: none;
      color: white;
    }
  `,
]

function ZscalarAcquisition() {
  return (
    <div css={wrapper}>
      <div css={top}>
        <div css={topItem}>
          <img src={ZscalerLogo} alt="Zscaler Logo" height="56" />
        </div>
        <div css={topItem}>
          <img src={SmokescreenLogo} alt="Smokescreen Logo" height="57" />
        </div>
      </div>
      <h2 css={heading}>Smokescreen is proud to be part of Zscaler</h2>
      <a
        href="https://www.zscaler.com/press/zscaler-acquire-smokescreen-enhance-zscaler-zero-trust-exchange-advanced-active-defense"
        css={button}
      >
        Learn More
      </a>
    </div>
  )
}

export default ZscalarAcquisition
