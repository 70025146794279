import routes from "../../../constants/routes"

const content = {
  heroImage: {
    headingText: "No-nonsense Threat Detection for Lateral Movement",
    descriptionText:
      "We use deception technology to blanket your network with decoys to catch the serious bad attackers.\n You only get 100% confirmed alerts so you can focus on stuff that matters.",
    buttonText: "Try Illusionblack for free",
    buttonHelpText: "30-day trial. No credit card required",
  },
  customerSuccess: {
    ratingHeading: "AVERAGE CUSTOMER\n SUCCESS RATING - Q3 2019",
    ratingText: "9.3 / 10",
  },
  upAndRunning: {
    subtitleText: "Simple Implementation",
    headingText: "Up and running in\nminutes, not months",
    descriptionText:
      "Launch your first deception campaign using ready-made decoys. Focus on detecting threats instead of wasting countless man-days configuring a new solution.",
    buttonText: "Schedule a demo",
  },
  signal: {
    subtitleText: "Low false positives",
    headingText: "All the signal.\nNone of the noise.",
    descriptionText:
      "Any interaction with an IllusionBLACK decoy is a high-confidence indicator of a breach. When you get an alert, you know it’s the real deal.",
    buttonText: "Schedule a demo",
  },
  automation: {
    subtitleText: "Easy to use",
    headingText: "Save countless hours of investigation",
    descriptionText:
      "Automated forensics and root-cause analysis in two clicks. Accomplish more in a fraction of the time with half the team.",
    buttonText: "Schedule a demo",
  },
  orchestration: {
    subtitleText: "Orchestration",
    headingText: "Stop threats\nwhile you sleep",
    descriptionText:
      "Out-of-the-box integrations with SIEMs, Firewalls, EDRs, Proxy, threat intel feeds, SOAR, and more. Forward events, contain threats and enrich alerts without any manual effort.",
    buttonText: "Schedule a Demo",
    buttonLink: routes.demo,
  },
}

export default content
