import React from "react"
import { Helmet } from "react-helmet"
import _get from "lodash/get"

import * as util from "src/util"

function CompanyStructuredData() {
  return (
    <Helmet>
      <script type="application/ld+json" key="structured-data">
        {`
            {
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "Smokescreen",
                "url": "https://www.smokescreen.io",
                "logo": "https://smokescreen.io/logo.png",
                "contactPoint": {
                  "@type": "ContactPoint",
                  "telephone": "+912261063851",
                  "contactType": "sales",
                  "availableLanguage": "en"
                },
                "sameAs": [
                  "https://twitter.com/smokescreentech",
                  "https://www.linkedin.com/company/smokescreen-technologies-pvt--ltd-",
                  "https://www.smokescreen.io",
                  "https://www.youtube.com/channel/UC4jAfl4JlTBg2yCMBDVab3w"
                ]
            }
            `}
      </script>
    </Helmet>
  )
}

function BlogStructuredData({ post, siteUrl, pathname }) {
  const siteUrlWithoutForwardSlash = siteUrl.replace(/\/$/g, "")
  const url = `${siteUrlWithoutForwardSlash}/${pathname}`
  const headline = post.title
  const description =
    _get(post, "yoast_meta.yoast_wpseo_metadesc") ?? post.excerpt
  const datePublished = post.date
  const dateModified = post.modified
  const author = post.author?.name
  let ogImage =
    _get(post, "yoast_meta.yoast_wpseo_opengraph_image.source_url") ||
    _get(post, "featured_media.source_url")
  const image = util.replaceImageSEOBase(ogImage, siteUrlWithoutForwardSlash)
  const schema = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": url,
    },
    headline,
    description,
    publisher: {
      "@type": "Organization",
      name: "Smokescreen",
      logo: {
        "@type": "ImageObject",
        url: "https://smokescreen.io/logo.png",
      },
    },
    datePublished,
    dateModified,
  }
  if (author) {
    schema.author = {
      "@type": "Person",
      name: author,
    }
  }
  if (author && author.toUpperCase() === "SMOKESCREEN TEAM") {
    schema.author["@type"] = "Organization"
  }
  if (image) {
    schema.image = image
  }
  return (
    <Helmet>
      <script type="application/ld+json" key="structured-data">
        {JSON.stringify(schema)}
      </script>
    </Helmet>
  )
}

export { BlogStructuredData, CompanyStructuredData }
