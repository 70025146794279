import React from "react"
import { graphql } from "gatsby"

import PageLayout from "../components/PageLayout"
import SEO from "../components/seo"
import { CompanyStructuredData } from "../components/structured-data"
import ogImage from "images/seo/home.jpeg"

import Homepage from "../views/homepage"

const IndexPage = ({ data }) => (
  <div>
    <SEO
      title="Smokescreen: Deception Technology"
      appendSeparator={false}
      description="Detect targeted threats like reconnaissance, spear phishing, lateral movement, stolen credentials and data theft with Smokescreen's deception technology"
      image={ogImage}
    />
    <CompanyStructuredData />
    <PageLayout options={{ headingBackground: false }}>
      <Homepage data={data} />
    </PageLayout>
  </div>
)

export const query = graphql`
  query {
    heroImage: file(relativePath: { eq: "homepage/hero-image.png" }) {
      childImageSharp {
        fluid(maxWidth: 1100, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    costSectionImage: file(relativePath: { eq: "homepage/cost-image-2x.png" }) {
      childImageSharp {
        fluid(maxWidth: 530) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default IndexPage
